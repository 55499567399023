<template>
    <div class="GoodsCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row style="margin-top: 16px">
                    <ef-dept-type-and-dept-select
                        @selectDept="setDeptAndQueryHasBigUnit"
                        @selectDeptType="setDeptType"
                    />
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="名称/条码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-alert
                title="非门店类型的机构不支持配置非标品,保存非标品时将会产生错误提示"
                center
                type="warning"
                close-text="知道了"
            >
            </el-alert>
            <el-button type="primary" @click="queryAndAdd" size="small">查询并添加</el-button>
            <el-button type="primary" @click="handleSelectSku" size="small">选择商品基本信息</el-button>
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" text-align="left">
            <el-table
                id="printMe"
                border
                stripe
                :rules="rules"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column align="center" fixed="left" label="序号" type="index" width="55"></el-table-column>
                <el-table-column prop="sku.name" label="商品名称" min-width="160" />
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.sku.bars" :key="b">{{ b }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="商品类型" width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | type }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.category" label="类目" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="80" />
                <el-table-column prop="sku.expirationDate" label="保质期" width="80" />
                <el-table-column label="单位（大）" v-if="meta.hasBigUnit" width="120">
                    <template slot-scope="scope">
                        <el-input :disabled="scope.row.sku.type === 1" size="mini" v-model="scope.row.bigUnit" />
                    </template>
                </el-table-column>
                <el-table-column label="包装因子" v-if="meta.hasBigUnit" width="100">
                    <template slot-scope="scope">
                        <el-input :disabled="scope.row.sku.type === 1" size="mini" v-model="scope.row.packFactor" />
                    </template>
                </el-table-column>
                <el-table-column prop="customBar" label="自编码" width="130">
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.customBar"
                            size="mini"
                            oninput="value=value.replace(/[^\d]/g,'')"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="130">
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.fastBar"
                            size="mini"
                            oncontextmenu="value=value.replace(/[^\a-zA-Z0-9\u4E00-\u9FA5]/g,'')"
                            onpaste="value=value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,'')"
                            onkeyup="value=value.replace(/[^\a-zA-Z0-9\u4E00-\u9FA5]/g,'')"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="minStock" label="最低库存" width="100">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.minStock" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column prop="maxStock" label="最高库存" width="100">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.maxStock" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="75">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <pickupSku ref="pickupSku" @ok="handleSelectSkuOk" />
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import qs from 'qs';
import PickupSku from '../../../components/PickupSku';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import Util from 'js/Util';
import { DeptTypeEnum } from 'js/DeptCommon';
import { countGreaterThanZeroValidateRule } from 'js/validate/ValidateStockBizRule';

export default {
    name: 'GoodsCreate',
    components: { PickupSku, EfDeptTypeAndDeptSelect },
    data() {
        return {
            form: {
                deptCode: '',
                deptType: '',
                search: '',
            },
            tableData: [],
            fastBar: '',
            url: {
                querySku: '/goods/sku/page?page=1&limit=20&search=',
                querySkuByBar: '/goods/sku/querySkuByDeptCode?page=1&limit=1000',
                querySkuListByCodes: '/goods/sku/querySkuList',
                batchSave: '/goods/goods/createExtend/',
                hasBigUnit: '/system/deptPrivilege/hasDeptPrivilege',
            },
            meta: {
                hasBigUnit: false,
            },
            rules: {
                minStock: countGreaterThanZeroValidateRule(),
            },
        };
    },
    mounted() {
        this.tableData.minStock = this.minStock;
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type === 0) {
                return '标品';
            }
            if (type === 1) {
                return '非标品';
            }
        },
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status === '0') {
                return '已下架';
            }
            if (status === '1') {
                return '已上架';
            }
            if (status === '2') {
                return '已禁售';
            }
        },
    },
    methods: {
        setDeptAndQueryHasBigUnit(deptCode, deptName, deptType) {
            this.form.deptCode = deptCode;
            this.form.deptType = deptType;
            if (Util.isEmpty(deptCode)) {
                return;
            }
            const str = qs.stringify({
                deptCodes: deptCode,
                flag: 'dept.biz.goods.allow.bigUnit',
            });
            UrlUtils.QueryRemote(this, this.url.hasBigUnit + '?' + str, (res) => {
                this.meta.hasBigUnit = res;
            });
            //切换机构时 单位(大) 包装因子清空
            this.tableData.forEach((a) => {
                a.bigUnit = null;
                a.packFactor = null;
            });
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        handleSave() {
            //校验数据
            if (Util.isEmpty(this.form.deptCode)) {
                this.$message.error('请选择机构');
                return;
            }
            if (!this.tableData.length) {
                return;
            }
            if (this.tableData.length > 20) {
                this.$message.error('批量新建一次不能超过20条');
                return;
            }
            for (let i = 0; i < this.tableData.length; i++) {
                if (!this.validRow(this.tableData[i])) {
                    return;
                }
            }
            const param = {
                deptCode: this.form.deptCode,
                deptType: this.form.deptType,
                skuCode: this.tableData.map((r) => r.sku.code || ' '),
                customBar: this.tableData.map((r) => r.customBar || ''),
                fastBar: this.tableData.map((r) => r.fastBar || ''),
                maxStock: this.tableData.map((r) => r.maxStock),
                minStock: this.tableData.map((r) => r.minStock),
                bigUnit: this.tableData.map((r) => r.bigUnit || ' '),
                packFactor: this.tableData.map((r) => r.packFactor || ' '),
                type: this.tableData.map((r) => r.sku.type),
                isHasBigUnitPriAdd: this.meta.hasBigUnit,
            };
            UrlUtils.PostRemote(this, this.url.batchSave, param);
        },
        queryAndAdd() {
            const _this = this;
            if (!this.form.search.trim()) {
                return;
            }
            const _params = { params: { search: this.form.search, deptCode: this.form.deptCode } };
            Util.queryTable(this, this.url.querySkuByBar, _params, (data) => {
                let _a = this.tableData;
                data.data.forEach((v) => {
                    v.status = 0;
                    _a.push({
                        bars: v.bars,
                        sku: v,
                        skuCode: v.code,
                        customBar: '',
                        fastBar: v.nameFirstSpell,
                        maxStock: 999,
                        minStock: 1,
                        type: v.type,
                        bigUnit: '',
                        packFactor: '',
                    });
                });
                _a = _this.duplicate(_a, 'skuCode');
                _this.tableData = _a;
            });
        },
        handleSelectSkuOk(data) {
            const _this = this;
            const skuCodes = data.map((item) => {
                return item.code;
            });
            const _params = { params: { skuCodes: skuCodes, deptCode: this.form.deptCode } };
            Util.queryTable(this, this.url.querySkuListByCodes, _params, (data) => {
                let _a = this.tableData;
                data.data.forEach((v) => {
                    v.status = 0;
                    _a.push({
                        bars: v.bars,
                        sku: v,
                        skuCode: v.code,
                        customBar: '',
                        fastBar: v.nameFirstSpell,
                        maxStock: 999,
                        minStock: 1,
                        type: v.type,
                        bigUnit: '',
                        packFactor: '',
                    });
                });
                _a = _this.duplicate(_a, 'skuCode');
                _this.tableData = _a;
            });
        },
        handleSelectSku() {
            //配送中心机构类型只能查询标品商品
            this.$refs.pickupSku.show({ type: this.form.deptType === DeptTypeEnum.REPOSITORY ? 0 : null });
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
        validRow(ln) {
            if (
                this.meta.hasBigUnit && //大单位
                !ln.type && //标品
                ln.bigUnit.trim().length <= 0 &&
                ln.bigUnit.trim().length < 6 &&
                !/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test((ln.bigUnit || '').trim())
            ) {
                //
                this.$message.error('大单位只能输入5位以内数字字母或汉字');
                return false;
            }
            if (ln.type === 0) {
                if (this.meta.hasBigUnit && !ln.type && !/\d{1,5}/.test(ln.packFactor)) {
                    this.$message.error('标品包装因子只能输入5位以内正整数且不能为空,请重新输入');
                    return false;
                }
            }
            if (ln.customBar && !window.ef.someRegs.customBar.test(ln.customBar)) {
                this.$message.error('请输入1-15位数字自编码');
                return false;
            }
            if (ln.fastBar && !window.ef.someRegs.fastBar.test(ln.fastBar)) {
                this.$message.error('请输入1-15数组或字母位助记码');
                return false;
            }
            if (ln.customBar && ln.customBar.toString() === ln.fastBar) {
                this.$message.error('请勿输入相同的助记码和自编码');
                return false;
            }
            if (ln.minStock <= 0) {
                this.$message.error('请输入正整数的最低库存');
                return false;
            }
            if (ln.maxStock <= 0) {
                this.$message.error('请输入正整数的最高库存');
                return false;
            }
            if (ln.minStock > ln.maxStock) {
                this.$message.error('最低库存不可大于最高库存');
                return false;
            }
            return true;
        },
    },
};
</script>
